import { useProfileData } from '../AuthProvider'
import SidebarNavigation from '../SidebarNavigation'
import TemplateFullWidth from '../TemplateFullWidth'
import Image1 from './1.png'
import * as style from './style.module.scss'
import Link from 'gatsby-link'
import React from 'react'

const PageDashboard = (props) => {
  const listing = props.data.listing
  const profile = useProfileData()

  return (
    <TemplateFullWidth>
      <div className={style.pageWrapper}>
        <SidebarNavigation />
        <div className={style.content}>
          <div className={style.headerContainer}>
            <div>
              <h1>Dashboard</h1>
              <p className={style.subline}>
                Welcome, Jacqueline! Please finish setting up your{' '}
                <Link to={'/edit/'}>profile</Link> and calendar to go live.
                <pre>
                  {profile ? JSON.stringify(profile, null, 2) : 'Logged out'}
                </pre>
              </p>
            </div>
            <div className={style.profileDetails}>
              <input
                title='Your profile URL'
                className={style.url}
                disabled
                value='https://doggos.com/vacay-at-the-dog-castle'
                type='text'
              />
              <div className={style.buttonRow}>
                <div className={style.status}>
                  <span></span>Not live
                </div>
                <div className={style.buttonRow}>
                  <div className={style.button}>Share profile</div>
                  <div className={style.button}>View profile</div>
                </div>
              </div>
            </div>
          </div>
          <div className={style.hero}>
            <div className={style.media}>
              <div className={style.gettingStarted}>
                <h3>Getting started</h3>
                <p>
                  Find answers to common questions from dog sitters, suggestions
                  on setting up your profile, and more on the blog.
                </p>
                <Link className={style.button} to={'/blog-posts'}>
                  View resources
                </Link>
                <img className={style.backgroundImage} src={Image1} />
              </div>
            </div>
            <div className={style.media}>
              <div className={style.stats}>
                <div>
                  <h5>Bookings</h5>
                  <div className={style.statRow}>
                    <p>0 active</p>
                    <p>0 pending</p>
                  </div>
                  <h5>Balance</h5>
                  <p>$0.00 USD</p>
                </div>
                <div className={style.profile}>
                  <Link
                    className={style.button}
                    to={'/vacay-at-the-dog-castle'}
                  >
                    Edit profile
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className={style.calendarSection}>
            <h2>My calendar</h2>
            <p className={style.subline}>
              Start accepting bookings by setting up your availability and rates
            </p>
            <div className={style.calendar}></div>
            <div className={style.buttonContainer}>
              <div className={style.button}>Manage availability</div>
            </div>
          </div>
        </div>
      </div>
    </TemplateFullWidth>
  )
}

export default PageDashboard
